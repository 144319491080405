import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import Seo from "../components/global/seo/seo.jsx"
const BankaBilgileri = () => {
  const resimler = useStaticQuery(graphql`
    query {
      isbank: file(relativePath: { eq: "banka/isbank.png" }) {
        childImageSharp {
          fixed(height: 58) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      garanti: file(relativePath: { eq: "banka/garanti.png" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Banka Bilgilerimiz - Paslanmaz Filtre Erez Kaynak"
        description="Banka Bilgilerimiz EREZ KAYNAK Uğur Erkin DEMİRKAYA İkitelli Org.San.Bölgesi Dersan Koop San.Sit. S6A Blok No:121 Başakşehir / İSTANBUL İkitelli VD. V.No: 31198823454 Mersis No: 3119882345400001 Ticaret Sicil No: 102885-5 Garanti Bankası : Hesap adı: Uğur Erkin DEMİRKAYA Şube : 1440 Hesap No:6297862 IBAN:TR42 0006 2001 4400 0006 2978 62 işbank şube:1021 hesap no:1463248 iban:IBAN"
        keywords="erez kaynak hesap bilgileri, erez kaynak işbank, erez kaynak garanti bank, erez kaynak havale, erez kaynak eft"
      />
      <Box my={3}>
        <Paper elevation={4}>
          <Typography variant="h4" component="h1">
            <Box pt={3} textAlign="center" fontWeight={500}>
              Banka Bilgilerimiz
            </Box>
          </Typography>
          <Grid container alignItems="center" direction="row">
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box p={3} textAlign="center">
                <Img
                  className="img-fluid"
                  fluid={resimler.logo.childImageSharp.fluid}
                  alt="Erez Kaynak Tanıtım"
                />
                <br />
                <strong>Uğur Erkin DEMİRKAYA</strong>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box px={3}>
                <Lead>
                  İkitelli Org.San.Bölgesi Dersan Koop San.Sit. S6A Blok No:121
                  <br />
                  Başakşehir / İSTANBUL
                </Lead>
                <Lead>
                  <span>İkitelli VD. V.No: </span>31198823454
                  <br />
                  <span>Mersis No: </span>3119882345400001
                  <br />
                  <span>Ticaret Sicil No: </span>102885-5
                  <br />
                  <span>İş yerimizde kredi kartı geçerlidir.</span>
                  <br />
                  <Box py={1} color="secondary.main">
                  11.03.2021 Tarihinden itibaren hesap bilgilerimiz değişmiştir.
                  </Box>
                </Lead>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box my={4}>
        <Grid container spacing={3} alignItems="center" direction="row">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper elevation={4}>
              <Box py={3} textAlign="center">
                <Img
                  className="img-fluid"
                  fixed={resimler.garanti.childImageSharp.fixed}
                  alt="Erez Kaynak Garanti Bank"
                />
                <br />
                <strong>Garanti Bankası</strong>
              </Box>
              <Box px={3} pb={3}>
                <Lead>
                  <span>Hesap adı: </span>Uğur Erkin DEMİRKAYA
                  <br />
                  <span>Şube: </span>1440
                  <br />
                  <span>Hesap No: </span>6297862
                  <br />
                  <span>IBAN: </span>TR42 0006 2001 4400 0006 2978 62
                </Lead>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper elevation={4}>
              <Box py={4} textAlign="center">
                <Img
                  className="img-fluid"
                  fixed={resimler.isbank.childImageSharp.fixed}
                  alt="Erez Kaynak iş Bank"
                />
                <br />
                <strong>Ziraat Bankası</strong>
              </Box>
              <Box px={3} pb={3}>
                <Lead>
                  <span>Hesap adı: </span>Uğur Erkin DEMİRKAYA
                  <br />
                  <span>Şube: </span>2406
                  <br />
                  <span>Hesap No: </span>561942675006
                  <br />
                  <span>IBAN: </span>TR05 0001 0024 0656 1942 6750 06
                </Lead>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}
export default BankaBilgileri
